import HBusService from 'Service/HBusService';
import { getServiceType, getSiteType } from 'Utils/helper';

export const vasViewEvent = data => {
  HBusService.sendEventRequest('ValueAddedService', {
    event_type: 'View',
    page_type: 'pdp',
    page_value: 'VasBox',
    service: getServiceType(),
    site_type: getSiteType(),
    text: 'View',
    data,
    ...prepareCommonEvents(data)
  });
};

export const vasAddClickEvent = data => {
  HBusService.sendEventRequest('ValueAddedService', {
    event_type: 'Click',
    page_type: 'pdp',
    page_value: 'VasBox',
    service: getServiceType(),
    site_type: getSiteType(),
    placement: 'button',
    text: 'Ekle',
    data,
    ...prepareCommonEvents(data)
  });
};

export const vasRemoveClickEvent = data => {
  HBusService.sendEventRequest('ValueAddedService', {
    event_type: 'Click',
    page_type: 'pdp',
    page_value: 'VasBox',
    service: getServiceType(),
    site_type: getSiteType(),
    placement: 'button',
    text: 'Kaldır',
    data,
    ...prepareCommonEvents(data)
  });
};

const prepareCommonEvents = (vas) => {
  let vasSelect =  vas?.vases || vas //for View and Remove-Add event seperate
  let utagDataSelect = window?.utagData;
  let productModelSelect = window?.productModel?.product;
  let categorySelect = productModelSelect?.categories?.[productModelSelect?.categories?.length - 1] || [];
  return {
    category_hierarchy: window?.['hepsiBus']?.parsers?.productList?.getCategoryHierarchy?.() || null,
    merchant: {
      id: productModelSelect?.currentListing?.merchantId || '',
      name: productModelSelect?.currentListing?.merchantName || ''
    },
    category: {
      id: categorySelect?.categoryId || null,
      name: categorySelect?.categoryName || null
    },
    products: [
      {
        product_id: utagDataSelect?.product_ids && utagDataSelect?.product_ids?.[0] || '',
        listingId: utagDataSelect?.listing_ids && utagDataSelect?.listing_ids?.[0] || '',
        merchant: utagDataSelect?.merchant_names && utagDataSelect?.merchant_names?.[0] || '',
        name: utagDataSelect?.product_name_array || '',
        url: utagDataSelect?.page_url || '',
        price: utagDataSelect?.order_subtotal && utagDataSelect?.order_subtotal?.[0] || '',
        sku: utagDataSelect?.product_skus && utagDataSelect?.product_skus?.[0] || '',
        brand: utagDataSelect?.product_brand || '',
        product_status: utagDataSelect?.product_status || '',
        shipping_type: utagDataSelect?.shipping_type?.[0] || '',
        position: '1',
      }
    ],
    vas_product: {
      ...vasSelect,
    }
  };
}

export default {
  vasViewEvent,
  vasAddClickEvent,
  vasRemoveClickEvent
};
