import { CustomListTypeEnums } from 'Model/Enums';
import { bifrostUrl } from '../../appConfig';
import BaseService from '../BaseService';

class CustomListService {
  validateStatusSuccess = status => {
    return status >= 200 && status < 500;
  };

  getCustomLists = (params, validateStatus = true) => {
    let requestObject = {};
    if (params) {
      requestObject = {
        params: {
          ...params,
          a: 'e'
        }
      };
    }

    if (!validateStatus) {
      requestObject.validateStatus = this.validateStatusSuccess;
    }

    // Gotto do something with response status. Had to create axios instance from createAxiosInstance
    return BaseService.get(`${bifrostUrl}/mylist-api/v1/summary`, requestObject);
  };

  getMyListDetail = (listType, listId = 0, page = 1) => {
    const LIMIT = 24;
    const SORT_ID = -1;
    let options = {
      params: {
        limit: LIMIT,
        offset: (page - 1) * LIMIT,
        sortId: SORT_ID
      }
    };
    let listUrl = '';
    switch (listType) {
      case CustomListTypeEnums.FAVORITES:
        listUrl = 'mylists/favorites';
        break;
      case CustomListTypeEnums.SAVE_FOR_LATER:
        listUrl = 'mylists/save-for-later';
        options = {
          ...options,
          headers: {
            'Cache-Control': 'private'
          }
        };
        break;
      case CustomListTypeEnums.GIFT_LIST:
        listUrl = `gift-list/${listId}`;
        break;
      default:
        listUrl = `mylists/${listId}`;
    }

    return BaseService.get(`${bifrostUrl}/mylist-api/v1/${listUrl}/items`, options);
  };

  addItemToList = (listId, listType, requestObject) => {
    const params = {};

    let requestURL = `/mylists/${listId}/items`;

    switch (listType) {
      case CustomListTypeEnums.FAVORITES:
        params.validateStatus = this.validateStatusSuccess;
        requestURL = '/mylists/favorites/items';
        break;
      case CustomListTypeEnums.SAVE_FOR_LATER:
        requestURL = '/mylists/save-for-later/items';
        break;
      case CustomListTypeEnums.GIFT_LIST:
        requestURL = `/gift-list/${listId}/items`;
        break;
      default:
        break;
    }
    return BaseService.post(`${bifrostUrl}/mylist-api/v1${requestURL}`, requestObject, params);
  };

  deleteItemFromList = (listId, listType, requestObject) => {
    let requestURL = `/mylists/${listId}/items`;

    switch (listType) {
      case CustomListTypeEnums.FAVORITES:
        requestURL = '/mylists/favorites/items';
        break;
      case CustomListTypeEnums.SAVE_FOR_LATER:
        requestURL = '/mylists/save-for-later/items';
        break;
      case CustomListTypeEnums.GIFT_LIST:
        requestURL = `/gift-list/${listId}/items`;
        break;
      default:
        break;
    }

    return BaseService.delete(`${bifrostUrl}/mylist-api/v1${requestURL}`, { data: requestObject });
  };

  createList = requestObject => {
    return BaseService.post(`${bifrostUrl}/mylist-api/v1/mylists`, requestObject);
  };

  getFavoritesItemSku = () => {
    return BaseService.get(`${bifrostUrl}/mylist-api/v1/mylists/favorites/items/skus`);
  };

  getMyListBySkus = skus => {
    return BaseService.post(`${bifrostUrl}/mylist-api/v1/summary`, skus);
  };

  createAlarm = (requestObject, withPermission = null) => {
    let config = {};
    if (withPermission !== null && withPermission !== undefined) {
      config = {
        headers: {
          'is-permission': withPermission
        }
      };
    }

    return BaseService.post(
      `${bifrostUrl}/mylist-api/v1/mylists/alarms/permission/items`,
      requestObject,
      config
    );
  };

  deleteAlarm = requestObject => {
    return BaseService.delete(`${bifrostUrl}/mylist-api/v1/mylists/alarms/items`, {
      data: requestObject
    });
  };

  kvkkApprove = requestObject => {
    return BaseService.post(`${bifrostUrl}/api/Customer/SetDataSharePermission`, requestObject);
  };

  createListV2 = requestObject => {
    return BaseService.post(`${bifrostUrl}/api/v2/lists`, requestObject);
  };

  updateMyLists = requestObject => {
    return BaseService.patch(`${bifrostUrl}/api/v2/lists-items/bulk`, requestObject);
  };

  getLists = ({ offset, listName, sku }) => {
    const requestObject = {
      params: {
        limit: 24,
        offset,
        listName,
        sku
      },
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate'
      }
    };
    return BaseService.get(`${bifrostUrl}/api/v2/lists/summary/search`, requestObject);
  };

  getListsForCheckout = ({ offset, listName, sku }) => {
    const requestObject = {
      params: {
        limit: 24,
        offset,
        listName,
        sku,
        emptyListIgnore: true
      }
    };
    return BaseService.get(`${bifrostUrl}/api/v2/lists/summary/search`, requestObject);
  };

}

export default new CustomListService();
