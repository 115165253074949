import {
  CustomListTypeEnums,
  CustomListNameEnums,
  StaticFileEnums,
  ProductStatusTypeEnums,
  RecommendationLabelNames
} from 'Model/Enums';
import * as appConfig from '../appConfig';
import { loginUrl } from '../appConfig';
import jwtDecode from 'jwt-decode';
import Cookie from 'js-cookie';
import CookieService from 'Service/CookieService';

export const redirectWithPrefix = (prefix = '') => {
  window.location.href = `${loginUrl}${encodeURIComponent(window.location.href + prefix)}`;
};

export const getDefaultLists = allLists => {
  return allLists
    .filter(
      x =>
        x.listType !== CustomListTypeEnums.GIFT_LIST &&
        x.listType !== CustomListTypeEnums.CUSTOM_LIST &&
        x.listType !== CustomListTypeEnums.ALARM_LIST
    )
    .sort((x, y) => x.listType - y.listType);
};

export const getCustomLists = allLists => {
  return allLists.filter(x => x.listType === CustomListTypeEnums.CUSTOM_LIST);
};

export const getGiftLists = allLists => {
  return allLists.filter(x => x.listType === CustomListTypeEnums.GIFT_LIST);
};

const QUERY_STRING_PREFIX = '?';

export const removeQueryStringFromUrl = url => {
  const partials = url.split(QUERY_STRING_PREFIX);

  return partials[0];
};

export const isBrowser = () => {
  return process.env.BROWSER && window;
};

export const isMobileDeviceResolution = () => {
  return isBrowser() && window.innerWidth <= 1024;
};

export const hideScrollbar = () => {
  document.body.style.overflow = 'hidden';
};

export const showScrollbar = () => {
  document.body.style.overflow = null;
};

export const searchPrefixInUrl = (prefix = '') => {
  if (isBrowser()) {
    return window.location.href.includes(prefix);
  }
  return false;
};

export const checkListingId = object => {
  if (!object.listingId || object.listingId === '' || object.listingId === '""') {
    return {
      ...object,
      listingId: 'Hepsiburada'
    };
  }
  return object;
};

export const getSubHeaderTheme = color => {
  const lightColors = [
    '#fee5e5',
    '#b9b9b9',
    '#fce7c8',
    '#e7f6fc',
    '#c6d0ff',
    '#b9eadf',
    '#d4baf7',
    '#f9c3ed'
  ];
  if (lightColors.includes(color)) {
    return 'light';
  }

  return 'dark';
};

export const getStaticFileTitle = activePage => {
  switch (activePage) {
    case StaticFileEnums.CONFIDENTIALITY_AGREEMENT:
      return 'Çerez politikası';
    case StaticFileEnums.CONSENT_TEXT:
      return 'Kişisel verilerin işlenmesine ilişkin rıza metni';
    case StaticFileEnums.ELUCIDATION_TEXT:
      return 'Kişisel verilerin işlenmesine ilişkin aydınlatma metni';
    case StaticFileEnums.MEMBERSHIP_AGREEMENT:
      return 'Üyelik sözleşmesi';
    default:
      return '';
  }
};

export const getListName = listType => {
  switch (listType) {
    case CustomListTypeEnums.FAVORITES:
      return CustomListNameEnums.FAVORITES;
    case CustomListTypeEnums.SAVE_FOR_LATER:
      return CustomListNameEnums.SAVE_FOR_LATER;
    case CustomListTypeEnums.ALARM_LIST:
      return CustomListNameEnums.ALARM_LIST;
    case CustomListTypeEnums.GIFT_LIST:
      return CustomListNameEnums.GIFT_LIST;
    default:
      return CustomListNameEnums.CUSTOM_LIST;
  }
};

export const getListUrl = (listId, listType) => {
  const { myListUrl } = appConfig;
  switch (listType) {
    case CustomListTypeEnums.FAVORITES:
      return `${myListUrl}/begendiklerim`;
    case CustomListTypeEnums.SAVE_FOR_LATER:
      return `${myListUrl}/daha-sonra-alacaklarim`;
    case CustomListTypeEnums.ALARM_LIST:
      return `${myListUrl}/alarm-listem`;
    case CustomListTypeEnums.GIFT_LIST:
      return `${myListUrl}/hediye-listem/${listId}`;
    default:
      return `${myListUrl}/ozel-listelerim/${listId}`;
  }
};

export const showToast = params => {
  if (window.hbToast) {
    // eslint-disable-next-line no-undef
    hbToast.show(params);
  } else {
    setTimeout(() => {
      showToast(params);
    }, 100);
  }
};

export const setCookieForToast = params => {
  if (window.hbToast) {
    // eslint-disable-next-line no-undef
    hbToast.setCookie(params);
  } else {
    setTimeout(() => {
      setCookieForToast(params);
    });
  }
};

export const isComponentInView = componentRef => {
  if (isBrowser()) {
    const componentPosition = componentRef.getBoundingClientRect();
    const documentPosition = document.documentElement.getBoundingClientRect();

    const docViewTop = -documentPosition.top;
    const elemTop = componentPosition.top;
    const elemBottom = componentPosition.bottom;

    return elemBottom >= 0 && elemTop <= docViewTop;
  }
  return false;
};

/** Custom Validations */
export const checkStringContainsNumber = n => {
  const hasNumber = /\d/;
  return hasNumber.test(n);
};

export const checkStringContainsUppercase = str => {
  const regex = /[A-Z]/;
  return regex.test(str);
};

export const validateEmail = email => {
  const re = /^(([a-zA-Z\-0-9_-]+(\.[[a-zA-Z\-0-9]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const addedProductToSfl = product => {
  if (window?.CHECKOUT?.MyLists?.addedProductToSfl) {
    window.CHECKOUT.MyLists.addedProductToSfl(product);
  } else {
    setTimeout(() => {
      addedProductToSfl(product);
    }, 100);
  }
};

export const getMailName = email => {
  return email
    .toLowerCase()
    .replace(/.*@/, '')
    .split('.')[0];
};

export const isGmailDomain = email => {
  const domain = getMailName(email);
  return domain.toLowerCase() === 'gmail' ? 'Gmail' : 'TempMail';
};

export const convertPriceToInt = price => {
  return Number(price) || price;
};

export const getCoTesting = callback => {
  if (window?.coTesting) {
    callback(window?.coTesting);
  } else if (process.env.BROWSER && window.hepsiBus?.eventBus) {
    window.hepsiBus.eventBus.on('dgr.script.executed', () => {
      callback(window?.coTesting);
    });
  } else {
    callback({});
  }
};

export const isPhoneNumberValid = value => {
  if (!value) {
    return false;
  }
  const phoneNumberRegex = /[^+0-9]/g;

  if (value.replace(phoneNumberRegex, '') !== value) {
    return false;
  }
  const turkeyPhoneNumberRegex = /501\d|505\d|506\d|507\d|510\d|516\d|551\d|552\d|553\d|554\d|555\d|559\d|530\d|531\d|532\d|533\d|534\d|535\d|536\d|537\d|538\d|539\d|561\d|540\d|541\d|542\d|543\d|544\d|545\d|546\d|547\d|548\d|549\d/;

  if (value.indexOf('0090') === 0) {
    if (value.length === 14 && value.search(turkeyPhoneNumberRegex) === 4) {
      return true;
    }
    return false;
  }

  if (value.indexOf('+90') === 0) {
    if (value.length === 13 && value.search(turkeyPhoneNumberRegex) === 3) {
      return true;
    }
    return false;
  }

  if (value.indexOf('90') === 0) {
    if (value.length === 12 && value.search(turkeyPhoneNumberRegex) === 2) {
      return true;
    }
    return false;
  }

  if (value.indexOf('0') === 0) {
    if (value.length === 11 && value.search(turkeyPhoneNumberRegex) === 1) {
      return true;
    }
    return false;
  }

  if (value.indexOf('00') === 0 || value.indexOf('+') === 0) {
    // minumum length of global phone number is +290 XXXX
    if (value.length < 8) {
      return false;
    }
    return true;
  }

  if (value.search(turkeyPhoneNumberRegex) === 0 && value.length === 10) {
    return true;
  }
  return false;
};

/**
 * T.C. Kimlik No Doğrulama
 * @param {string} value
 * @returns {boolean}
 */
export const isIdentityValid = value => {
  const mod = (number, modNumber) => {
    const modResult = number % modNumber;
    return modResult < 0 ? modResult + modNumber : modResult;
  };

  const no = value.split('');
  let i;
  let total1 = 0;
  let total2 = 0;
  let total3 = parseInt(no[0], 10);
  for (i = 0; i < 10; i += 1) {
    total1 += parseInt(no[i], 10);
  }

  for (i = 1; i < 9; i += 2) {
    total2 += parseInt(no[i], 10);
    total3 += parseInt(no[i + 1], 10);
  }
  return (
    !(
      !/^[1-9][0-9]{10}$/.test(value) ||
      total1 % 10 !== Number(no[10]) ||
      mod(total3 * 7 - total2, 10) !== Number(no[9])
    ) && Number(value) !== 11111111110
  );
};

export const isDateValid = dateStr => {
  const regex = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/;

  if (dateStr.match(regex) === null) {
    return false;
  }

  const [day, month, year] = dateStr.split('/');

  if (year < 1900 || year > new Date().getFullYear()) {
    return false;
  }

  const isoFormattedStr = `${year}-${month}-${day}`;
  const date = new Date(isoFormattedStr);
  const timestamp = date.getTime();

  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false;
  }

  return date.toISOString().startsWith(isoFormattedStr);
};

const validateNumber = e => {
  const validNumber = new RegExp(/\d*([.,/]?\d+)/);
  let lastValid = '';
  if (validNumber.test(e.target.value)) {
    lastValid = e.target.value;
  } else {
    e.target.value = lastValid;
  }
};

export const autoDateFormatter = e => {
  validateNumber(e);
  const key = e.which || e.keyCode || 0;

  if (key === 8 || key === 46) {
    return false;
  }

  const strokes = e.target.value.length;

  if (strokes === 2 || strokes === 5) {
    let val = e.target.value;
    val = val.replace(/[^0-9/]/g, '');
    val += '/';
    e.target.value = val;
  }

  if (strokes >= 3 && strokes < 5) {
    const val = e.target.value;
    if (val.charAt(2) !== '/') {
      const str = `${val.slice(0, 2)}/${val.slice(2)}`;
      e.target.value = str;
    }
  }

  if (strokes >= 6) {
    const val = e.target.value;
    if (val.charAt(5) !== '/') {
      const str = `${val.slice(0, 5)}/${val.slice(5)}`;
      e.target.value = str;
    }
  }

  return true;
};

export const dateFormatter = dateStr => {
  const [day, month, year] = dateStr.split('/');

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const applicationType = key => {
  if (
    key === applicationKeys.MobileIphone ||
    key === applicationKeys.MobileIpad ||
    key === applicationKeys.MobileAndroid ||
    key === applicationKeys.LegacyMobileApp
  ) {
    return 'Mobile';
  }
  return 'Site';
};

export const getUserJWT = () => {
  return Cookie.get('jwt');
};

export const getCustomer = () => {
  const jwtCookie = getUserJWT();
  if (!jwtCookie) {
    return false;
  }

  let customer = null;

  const jwtInfo = jwtDecode(jwtCookie);

  if (jwtInfo) {
    customer = {
      ...jwtInfo,
      Application: applicationType(jwtInfo.AppKey)
    };
  }

  return customer;
};

const applicationKeys = {
  MobileIphone: 'BF448871-DB72-4CD7-A0E0-1F82BF410370',
  MobileAndroid: '8093F525-1BAC-49C0-8FB7-C9F7B2DA04CF',
  WebSite: 'AF7F2A37-CC4B-4F1C-87FD-FF3642F67ECB',
  MobileSite: 'D5BABFEB-AEE3-420B-9857-0697C8716A7E',
  MobileIpad: '8B497575-C938-4807-8060-130380F67F7C',
  BackOffice: 'DACEEFC8-68BC-40FB-B693-BC7198F42474',
  LegacyMobileApp: 'A0A16B0C-9FCE-48BB-8881-4AC263A938F3',
  KanalD: '71C6ED59-AA28-4852-8C7B-3FE28480E7EC'
};

export const getSiteType = () => {
  const customer = getCustomer();
  switch (customer?.AppKey) {
    case applicationKeys.MobileAndroid:
      return 'android_native';
    case applicationKeys.MobileIphone:
    case applicationKeys.MobileIpad:
      return 'ios_native';
    case applicationKeys.MobileSite:
      return 'mobile';
    default:
      return 'desktop';
  }
};

export const getQueryVariable = variable => {
  const query = window.location.search.substring(1);
  const vars = decodeURIComponent(query).split('&');

  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }

  return undefined;
};

export const isHXNativeApp = () => {
  const hxKey = Cookie.get('hx');
  return applicationType(hxKey) === 'Mobile';
};

export const getOidcReturnUrl = () => {
  return CookieService.getItem('oidcReturnUrl') || getQueryVariable('oidcReturnUrl');
};

export const isHepsiburadaSeyahat = () => {
  return getOidcReturnUrl()?.includes('hepsiburadaseyahat');
};

export const isHepsiFly = () => {
  return getOidcReturnUrl()?.includes('hepsifly') || isHepsiburadaSeyahat();
};

export const isHBToHepsiFly = () => {
  return getOidcReturnUrl()?.includes('ucak-bileti');
};

export const isHepsiFlyDomain = () => {
  return isHepsiFly() || isHBToHepsiFly();
};

export const getServiceType = () => {
  if (isHepsiFlyDomain()) {
    return 'HF';
  }
  if (isHXNativeApp()) {
    return 'HX';
  }

  return 'HB';
};

export const isAndroid = () => {
  const ua = window?.navigator?.userAgent?.toLowerCase();
  return ua.indexOf('android') > -1;
};

export const isNativeApp = () => {
  const appKey = Cookie.get('AppKey');
  return applicationType(appKey) === 'Mobile';
};

export const formatProductUrl = (url, sku) => {
  const isNative = isNativeApp();
  if (isNative) {
    return `hbapp://product?sku=${sku}`;
  }
  return url;
};

export const formatMyMoney = price => {
  const currencySymbol = '₺';

  const formattedOutput = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 2
  });

  return formattedOutput?.format(price).replace(currencySymbol, '');
};

const getProductDiscountType = discountType => {
  switch (discountType) {
    case 1: {
      return 'Discount';
    }
    case 2: {
      return 'ExtraDiscount';
    }
    default: {
      return 'NoDiscount';
    }
  }
};

export const PrepareMyListCard = {
  prepareVariantList: product => {
    const images = [];

    if (product?.media) {
      product?.media?.slice(0, 1)?.map(({ isDefault, linkFormat }) => {
        images.push({
          isDefault,
          link: linkFormat,
          height: 1500,
          width: 1125
        });
      });
    }

    const categorizedLabels = {
      valueAddedService: [
        {
          text: 'Tükendi',
          color: '646464'
        }
      ],
      campaign: null
    };

    const tempObject = {
      sku: product?.sku,
      name: product?.name,
      brand: product?.brand,
      url: formatProductUrl(product?.url, product?.sku),
      isDefault: true,
      procurable: !product?.isOutOfStock,
      listing: {
        priceInfo: {
          price: product?.finalPriceOnSale,
          originalPrice: product?.finalPrice,
          discountRate: product?.discountRate,
          discountType: getProductDiscountType(product?.discountType),
          description:
            product?.priceAddedToList > 0 &&
            `${formatMyMoney(product?.priceAddedToList)} TL'yken ekledin`,
          finalPriceOnSale: product?.finalPriceOnSale
        },
        isPreOrder: product?.isPreOrder,
        inStockDate: null,
        listingId: product?.listingId,
        categorizedLabels: product?.isOutOfStock && categorizedLabels
      },
      images
    };

    return [tempObject];
  },
  prepareVariants: product => {
    if (product?.variantProperty?.length) {
      return product?.variantProperty?.map(x => x.value);
    }
    return undefined;
  },
  prepareProduct: product => {
    const tempObject = {
      productId: product?.productId,
      brand: product?.brand,
      customerReviewCount: null,
      customerReviewRating: null,
      variantList: PrepareMyListCard.prepareVariantList(product),
      displayOptions: {
        isAdultProduct: false,
        hasColorVariant: false
      },
      priceAddedToList: product?.priceAddedToList,
      priceAddedListDescription:
        product?.priceAddedToList > 0 &&
        `${formatMyMoney(product?.priceAddedToList)} TL'yken ekledin`
    };

    return tempObject;
  }
};

const COLORS = {
  cokrenk:
    'linear-gradient(135deg, #e02020, #fa6400 17%, #f7b500 33%, #6dd400 50%, #0091ff 67%, #6236ff 83%, #b620e0)',
  beyaz: '#ffffff',
  kirmizi: '#ed242c',
  siyah: '#111111',
  mavi: '#53bfe6',
  mor: '#7622e5',
  gri: '#9b9b9b',
  gumus: '#e5e5e5',
  kahverengi: '#690000',
  krem: '#ffccaf',
  bej: '#FFECE0',
  lacivert: '#2d3769',
  yesil: '#5ad363',
  sari: '#f3a027',
  turuncu: '#ff6000',
  pembe: '#fb6f6f'
};

export const getColorsByProduct = product => {
  return product?.variantList?.map(variant => {
    const { displayValue, value } = variant?.properties?.Renk ?? {};

    return {
      displayName: displayValue ?? '',
      value: COLORS?.[value?.toLowerCase()] ?? ''
    };
  });
};

export const getListsName = listType => {
  let placementTitle = '';

  if (listType >= 0) {
    if (listType === CustomListTypeEnums.FAVORITES) {
      placementTitle = CustomListNameEnums.FAVORITES;
    }
    if (listType === CustomListTypeEnums.CUSTOM_LIST) {
      placementTitle = CustomListNameEnums.CUSTOM_LIST;
    }
  }

  return placementTitle;
};

export const capitalizeFirstLetter = string => {
  if (!string) return string;
  return string.charAt(0).toLocaleUpperCase('tr') + string.slice(1);
};

/**
 * Verilen elemanın overflow olup olmadığını kontrol eder.
 *  https://stackoverflow.com/a/9541579/6478359
 * @param {HTMLElement} param0
 * @returns {boolean}
 */
export const isOverflown = ({ clientWidth, clientHeight, scrollWidth, scrollHeight }) => {
  return scrollHeight > clientHeight || scrollWidth > clientWidth;
};

export const generateProductStatus = product => {
  let result;

  if (product.isPreOrder) {
    result = ProductStatusTypeEnums.PRE_ORDER;
  } else if (product.isOutOfStock) {
    result = ProductStatusTypeEnums.OUT_OF_STOCK;
  } else {
    result = ProductStatusTypeEnums.IN_STOCK;
  }
  return result;
};

export const prepareProductLabelsForEvent = categorizedLabels => {
  return RecommendationLabelNames.map(labelName => {
    const labels = categorizedLabels?.[labelName];
    const firstLabel = labels?.[0];

    return {
      type: labelName,
      id: firstLabel?.tagName || null,
      displayName: firstLabel?.url || firstLabel?.text || null
    };
  });
};

export const isIOSWebview =
  isBrowser() && /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);

export const isAndroidWebview = isBrowser() && typeof window.Android !== 'undefined';

export const isAppWebview = isIOSWebview || isAndroidWebview;

// trigger mobile
export const triggerMobileDevice = (method, requestObject) => {
  if (window.webkit) {
    window.webkit.messageHandlers[method].postMessage(requestObject);
  } else if (window.Android) {
    window.Android[method](JSON.stringify(requestObject));
  }
};

export const sumDig = n => {
  let a = 0;
  while (n > 0) {
    // eslint-disable-next-line operator-assignment
    a = a + (n % 10);
    // eslint-disable-next-line no-param-reassign
    n = parseInt(n / 10, 10);
  }
  return a;
};

export const isValidIMEI = n => {
  if (!n || n.length !== 15) return false;

  let sum = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = n.length; i >= 1; i--) {
    let d = n % 10;
    // Doubling every alternate digit
    // eslint-disable-next-line operator-assignment, eqeqeq
    if (i % 2 == 0) d = 2 * d;

    // Finding sum of the digits
    sum += sumDig(d);
    // eslint-disable-next-line no-param-reassign
    n = parseInt(n / 10, 10);
  }
  return sum % 10 === 0;
};

export const isTenant = tenantValue => {
  const tenantCookie = Cookie.get('tenant');
  return tenantCookie === tenantValue;
};
